<template>
  <main class="page">
    <h1 class="page__mobile-title">Уведомления</h1>
    <ContractorsListActions @change="actionsChange" @reset="actionsReset" />
    <Table
      :columns="columns"
      :rows="notifications.data || []"
      listClass="request-list"
      :total="notifications.paginatorInfo ? notifications.paginatorInfo.total : 0"
      :page="page"
      pagination
      :initialSortBy="vgtFormattedOrdering"
      @paginate="changePage"
      @sort="changeSort"
    >
      <div slot="empty" class="td-container">
        <span class="table-span">
          <LoadingIndicator v-if="loading" />
          <template v-else>Данных нет</template>
        </span>
      </div>
      <template slot="table-row" slot-scope="{ row, column }">
        <a @click.prevent="readNotification(row)" v-if="column.field === 'created_at'" class="td-container">
          <span class="date">
            <span class="date__main">{{ row.created_at | formatDate }}</span>
            <span class="date__sub">{{ row.created_at | formatTime }}</span>
          </span>
        </a>
        <a @click.prevent="readNotification(row)" v-if="column.field === 'event'" class="td-container">
          <span class="table-span">
            {{ row.event.name }}
          </span>
        </a>
        <a @click.prevent="readNotification(row)" v-if="column.field === 'user'" class="td-container">
          <span class="table-span">
            {{ getFrom(row) }}
          </span>
        </a>
        <a @click.prevent="readNotification(row)" v-if="column.field === 'comment'" class="td-container">
          <span class="table-span table-span--red">
            {{ row.comment }}
          </span>
        </a>
        <a @click.prevent="readNotification(row)" v-if="column.field === 'actions'" class="td-container">
          <span class="table-actions">
            <a
              :href="$router.resolve(getRoute(row)).href"
              @click.prevent="readNotification(row)"
              class="table-action"
            >
              <LoaderIcon v-if="loadingNotification === row.id" class="loading-rotate" />
              <EyeOnIcon v-else />
            </a>
          </span>
        </a>
      </template>
      <div slot="table-m-item" slot-scope="{ row }" class="card">
        <div class="card__header">
          <div class="card__item">
            <span class="card__item-title">Дата</span>
            <span class="card__item-value">
              {{ row.created_at | formatDate }} {{ row.created_at | formatTime }}
            </span>
          </div>
        </div>
        <div class="card__body">
          <div class="card__item">
            <span class="card__item-title">Событие</span>
            <span class="card__item-value">
              {{ row.event.name }}
            </span>
          </div>
          <div class="card__item">
            <span class="card__item-title">От</span>
            <span class="card__item-value">{{ getFrom(row) }}</span>
          </div>
          <div class="card__item">
            <span class="card__item-title">Комментарий</span>
            <span class="card__item-value">{{ row.comment }}</span>
          </div>
        </div>
        <div class="card__footer">
          <a
            @click.prevent="readNotification(row)"
            :to="getRoute(row.id)"
            class="btn-gray btn-gray--outline btn-gray--md btn-gray--icon-left"
          >
            <LoaderIcon v-if="loadingNotification === row.id" class="loading-rotate" />
            <span>Просмотр</span>
          </a>
        </div>
      </div>
    </Table>
  </main>
</template>

<script>
import ContractorsListActions from "./components/actions";
import Table from "@/components/Table";
import moment from "moment";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import EyeOnIcon from "../../components/svg/EyeOnIcon.vue";
import LoaderIcon from "../../components/svg/LoaderIcon.vue";

export default {
  name: "NotificationsList",
  data() {
    return {
      loading: false,
      page: 1,
      loadingNotification: null,
      ordering: [
        {
          orderColumn: "created_at",
          orderBy: "DESC",
        },
      ],
      users: null,
      start_date: null,
      end_date: null,
      events: null,
    };
  },
  computed: {
    me() {
      return this.$store.state.me;
    },
    vgtFormattedOrdering() {
      return this.ordering.map((o) => ({
        field: o.orderColumn,
        type: o.orderBy.toLowerCase(),
      }));
    },
    notifications() {
      return this.$store.state.notifications;
    },
    columns() {
      return [
        {
          label: "Дата",
          field: "created_at",
        },
        {
          label: "Событие",
          field: "event",
        },
        {
          label: "От",
          field: "user",
        },
        {
          label: "Комментарий",
          field: "comment",
        },
        {
          sortable: false,
          field: "actions",
        },
      ];
    },
  },
  created() {
    let storageVariables = localStorage.getItem("notifications_list." + this.me.id);
    if (storageVariables) {
      storageVariables = JSON.parse(storageVariables);
      Object.keys(storageVariables).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(this, key)) {
          this[key] = storageVariables[key];
        }
      });
    }
    this.getNotifications();
  },
  methods: {
    readNotification(n) {
      if (n.read_at) {
        this.$router.push(this.getRoute(n));
        return;
      }
      if (!this.loadingNotification) {
        this.loadingNotification = n.id;
        this.$store
          .dispatch("MARK_NOTIFICATION_READ", {
            context: this,
            variables: {
              id: n.id,
            },
          })
          .then(() => {
            this.loadingNotification = null;
            n.read_at = new Date();
            this.$router.push(this.getRoute(n));
          });
      }
    },
    getRouteName(sendable_type) {
      switch (sendable_type) {
        case "App\\Models\\Request":
          return "Request";
        case "App\\Models\\Access":
          return "Pass";
        default:
          return null;
      }
    },
    getRoute(n) {
      const routeName = this.getRouteName(n.sendable_type);
      if (routeName) {
        return { name: routeName, params: { id: n.sendable_id } };
      }
      return { name: "Notifications" };
    },
    getFrom(n) {
      if (n.user && n.user.company && n.user.company_name) {
        return n.user.company_name;
      }
      if (n.user) {
        return `${n.user.surname} ${n.user.name} ${n.user.patronymic}`;
      }
      return "-";
    },
    actionsChange(event) {
      this.page = 1;
      switch (event.name) {
        case "users":
        case "events":
          this.changeField(event);
          break;
        case "date":
          if (event.value) {
            const format = "YYYY-MM-DD HH:mm:ss";
            this.start_date = moment(event.value.start).format(format);
            this.end_date = moment(event.value.end).format(format);
          } else {
            this.start_date = null;
            this.end_date = null;
          }
      }
      this.getNotifications();
    },
    changeField(event) {
      if (event.value.length) {
        this[event.name] = event.value.map((p) => p.id);
      } else {
        this[event.name] = null;
      }
    },
    getNotifications() {
      if (!this.loading) {
        this.loading = true;
        this.$store.state.notifications = {};
        let variables = this.clean({
          first: 14,
          page: this.page,
          ordering: this.ordering,
          start_date: this.start_date,
          end_date: this.end_date,
          users: this.users,
          events: this.events,
        });
        localStorage.setItem("notifications_list." + this.me.id, JSON.stringify(variables));
        this.$store
          .dispatch("GET_NOTIFICATIONS_PAGINATE", {
            context: this,
            variables: variables,
          })
          .then(() => {
            this.loading = false;
          });
      }
    },
    clean(obj) {
      for (let propName in obj) {
        if (
          Object.prototype.hasOwnProperty.call(obj, propName) &&
          (obj[propName] === null || obj[propName] === undefined)
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },
    actionsReset() {
      this.page = 1;
      this.roles = null;
      this.start_date = null;
      this.end_date = null;
      this.status = null;
      this.getNotifications();
    },
    changePage(e) {
      this.page = e;
      this.getNotifications();
    },
    changeSort(event) {
      this.ordering = [];
      event.forEach((column) => {
        if (column.type.toUpperCase() !== "NONE") {
          this.ordering.push({
            orderColumn: column.field,
            orderBy: column.type.toUpperCase(),
          });
        }
      });
      this.getNotifications();
    },
  },
  components: {
    LoaderIcon,
    EyeOnIcon,
    LoadingIndicator,
    Table,
    ContractorsListActions,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/card.styl"
@import "~@/styles/parts/request.styl"
@import "~@/styles/mixins/svg.styl"
.contractor-btn {
  background var(--main_white)
  border 1px solid var(--main_light_gray)
  border-radius var(--button_radius)
  height 48px
  width 48px
  display inline-flex
  align-items center
  justify-content center

  .icon {
    width 18px
    height 18px
  }

  &--green {
    border-color var(--main_green_grass)
    background var(--main_green_grass)

    svg {
      svg(var(--main_white))
    }
  }

  &--red {
    border-color var(--error_red)

    svg {
      svg(var(--error_red))
    }
  }

  &--gray {
    border-color var(--main_light_gray)

    svg {
      svg(var(--main_light_gray))
    }
  }

  &__list {
    display inline-flex
    gap 12px
    align-items center
  }
}
</style>
