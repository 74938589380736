<template>
  <div class="actions">
    <div class="actions__mobile">
      <button type="button" class="btn-blue btn-blue--outline btn-blue--icon-left">
        <FilterIcon />
        <span>Фильтры</span>
      </button>
    </div>
    <div class="actions__buttons" v-if="type">
      <button
        v-for="(b, i) in buttons"
        :key="i"
        class="pill"
        :class="{ 'pill--selected': b.id === type.id }"
        type="button"
      >
        {{ b.title }}
      </button>
    </div>
    <div class="actions__filters">
      <div class="actions__filters-list">
        <FilterSelectComponent
          title="Событие"
          :loading="dataLoading"
          v-model="form.events.value"
          @input="
            $emit('change', {
              name: 'events',
              value: form.events.value,
            })
          "
          :options="events"
          multiple
        />
        <DateComponent
          range
          title="Дата"
          :loading="dataLoading"
          v-model="form.date.value"
          mode="dateTime"
          @input="
            $emit('change', {
              name: 'date',
              value: form.date.value,
            })
          "
        />
        <FilterSelectComponent
          title="Пользователь"
          v-if="!type"
          :loading="dataLoading"
          v-model="form.users.value"
          @input="
            $emit('change', {
              name: 'users',
              value: form.users.value,
            })
          "
          :options="users"
          multiple
        />
        <button class="btn btn--small" type="button" @click="reset">Сбросить</button>
      </div>
    </div>
  </div>
</template>

<script>
import FilterSelectComponent from "@/components/inputs/FilterSelectComponent";
import DateComponent from "@/components/inputs/DateComponent";
import FilterIcon from "../../../components/svg/FilterIcon";

export default {
  name: "NotificationsListActions",
  data() {
    return {
      dataLoading: true,
      type: null,
      users: [],
      events: [],
      form: {
        users: {
          value: null,
          message: null,
        },
        date: {
          value: null,
          message: null,
        },
        events: {
          value: null,
          message: null,
        },
      },
    };
  },
  computed: {
    me() {
      return this.$store.state.me;
    },
    buttons() {
      return [
        {
          id: null,
          title: "Уведомления",
        },
      ];
    },
  },
  created() {
    this.$store
      .dispatch("GET_NOTIFICATIONS_LIST_ACTIONS_DATA", {
        context: this,
      })
      .then(({ data }) => {
        if (data.NotificationFilterData) {
          this.users = data.NotificationFilterData.users.map((u) => ({
            id: u.id,
            name: this.getFrom(u),
          }));
          this.events = data.NotificationFilterData.events;

          let storageVariables = localStorage.getItem("notifications_list." + this.me.id);
          if (storageVariables) {
            storageVariables = JSON.parse(storageVariables);
            if (storageVariables.events) {
              this.form.events.value = this.events.filter((p) => storageVariables.events.includes(p.id));
            }
            if (storageVariables.users) {
              this.form.users.value = this.users.filter((p) => storageVariables.users.includes(p.id));
            }
            if (storageVariables.start_date && storageVariables.end_date) {
              this.form.date.value = {
                end: storageVariables.end_date,
                start: storageVariables.start_date,
              };
            }
          }
          this.dataLoading = false;
        }
      });
  },
  methods: {
    getFrom(u) {
      if (u.company && u.company_name) {
        return u.company_name;
      }
      return `${u.surname} ${u.name} ${u.patronymic}`;
    },
    reset() {
      Object.keys(this.form).forEach((fK) => {
        this.form[fK].value = null;
      });
      this.$emit("reset");
    },
  },
  components: {
    FilterIcon,
    DateComponent,
    FilterSelectComponent,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/table-actions.styl"
</style>
